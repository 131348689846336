import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// v-bg
//import bg from './plugins/bg'

Vue.config.productionTip = false

//Vue.use(bg)

// 自动注册components目录全局组件
/* function autoRegisterComponents(r) {
  r.keys().forEach(path => {
    let component = r(path).default
    Vue.component(component.name, component)
  })
}
autoRegisterComponents(require.context('./components', true, /\.vue$/)) */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
